import styled from 'styled-components'

export const NavList = styled.ul`
  display: flex;
  gap: ${({ theme }) => theme.rhythm(1 / 2)} ${({ theme }) => theme.rhythm(1)};
  margin-bottom: 0;
  margin-left: 0;
  flex-wrap: wrap;
`

export const NavListElement = styled.li`
  display: inline;
  margin-bottom: 0;
  ${({ theme }) => theme.scale(0.1)}

  .nav-active {
    font-weight: 700;
  }
  .nav-active:hover {
    font-weight: 800;
  }
`
