import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const styling = css`
  transition: font-weight 0.2s linear, box-shadow 0.2s linear;
  font-family: ${({ theme }) => theme.options.headerFontFamily.join(',')};

  &:hover {
    font-weight: ${({ theme }) => theme.options.boldWeight + 50};
  }
`

export const PageLink = styled(Link)`
  ${styling}
`

export const PageLinkShadowless = styled(PageLink)`
  box-shadow: none;
`

export const PageLinkExternal = styled.a`
  ${styling}
`
