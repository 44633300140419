import React from 'react'
import Highlight, { Prism } from 'prism-react-renderer'

export function HighlightCode(props) {
  return (
    <Highlight
      Prism={Prism}
      code={props.value}
      language={props.language}
      theme={undefined}
    >
      {({ className, tokens, getLineProps, getTokenProps }) => (
        <pre className={className}>
          <code>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </code>
        </pre>
      )}
    </Highlight>
  )
}
