import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider, css } from 'styled-components'

import { typography } from '../../utils/typography'
import { Nav } from './Nav'
import { smartypants } from '../../utils/smartypants'
import { PortableText } from '../content/PortableText'

const StyledLayout = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ theme }) => theme.rhythm(24)};
  padding: ${({ theme }) => theme.rhythm(1.5)}
    ${({ theme }) => theme.rhythm(3 / 4)};

  // Image captions
  figure img {
    width: 100%;
    height: auto;
    margin-bottom: 0;
  }
  figcaption p {
    font-style: normal;
    font-size: 85%;
    display: block;
    text-align: center;
  }

  footer p:last-child {
    margin-bottom: 0;
  }
`

const StyledLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
`

const siteHeadingStyle = css`
  margin-top: 0;
  font-family: Zilla Slab Title, Zilla Slab, serif;
  font-weight: 700;
`

const BigH1 = styled.h1`
  margin-bottom: ${({ theme }) => theme.rhythm(1.0)};
  ${siteHeadingStyle}
  ${({ theme }) => theme.scale(1.5)}
`

const SmallH1 = styled.h1`
  margin-bottom: ${({ theme }) => theme.rhythm(0.5)};
  ${siteHeadingStyle}
  ${({ theme }) => theme.scale(0.4)}
`

const copyrightYearBlock = {
  _key: 'copyrightkey',
  _type: 'span',
  marks: [],
  text: `© ${new Date().getFullYear()} `,
}

export function Layout({ bigTitle = false, children }) {
  const {
    config: { copyright, title },
  } = useStaticQuery(graphql`
    query SiteConfigQuery {
      config: sanityConfig(_id: { eq: "global-config" }) {
        copyright: _rawCopyright
        title
      }
    }
  `)

  // Add copyright year in front
  if (copyright[0].children[0]._key === 'copyrightkey') {
    // Replace block object, needed for hot reloading etc.
    copyright[0].children[0] = copyrightYearBlock
  } else {
    // Inject at front
    copyright[0].children.unshift(copyrightYearBlock)
  }

  let header

  const link = <StyledLink to={`/`}>{smartypants(title)}</StyledLink>

  if (bigTitle) {
    header = <BigH1>{link}</BigH1>
  } else {
    header = <SmallH1>{link}</SmallH1>
  }
  return (
    <ThemeProvider theme={typography}>
      <StyledLayout>
        <header>{header}</header>
        <Nav />
        <main>{children}</main>
        <footer>
          <PortableText text={copyright} renderContainerOnSingleChild={false} />
        </footer>
      </StyledLayout>
    </ThemeProvider>
  )
}
