import React from 'react'
import styled from 'styled-components'
import { useLocation } from '@reach/router'
import { bottomBorder } from '../../utils/bottomBorder'
import { PageLink } from '../styled/PageLink'
import { NavList, NavListElement } from '../styled/NavList'
import { NavCategories } from './NavCategories'

export const StyledNav = styled.nav`
  margin-bottom: ${({ theme }) => theme.rhythm(1)};
  ${bottomBorder}
`

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: 'nav-active' } : {}
}
const isActive = ({ isCurrent }) => {
  return isCurrent ? { className: 'nav-active' } : {}
}

// Matches the paths /blog, /blog/ and blog/1 (or another number)
const blogPathRegex = /^\/blog\/?[0-9]*$/

export function Nav() {
  const location = useLocation()

  return (
    <StyledNav>
      <NavList>
        <NavListElement>
          <PageLink to={'/'} getProps={isActive}>
            Home
          </PageLink>
        </NavListElement>
        <NavListElement>
          <PageLink to={'/about'} getProps={isActive}>
            About
          </PageLink>
        </NavListElement>
        <NavListElement>
          <PageLink to={'/blog'} getProps={isPartiallyActive}>
            Blog
          </PageLink>
        </NavListElement>
        <NavListElement>
          <PageLink to={'/projects'} getProps={isPartiallyActive}>
            Projects
          </PageLink>
        </NavListElement>
        <NavListElement>
          <PageLink to={'/tweets'} getProps={isPartiallyActive}>
            Tweets
          </PageLink>
        </NavListElement>
      </NavList>
      {blogPathRegex.test(location.pathname) && <NavCategories />}
    </StyledNav>
  )
}
