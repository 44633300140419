import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { HighlightCode } from './HighlightCode'
import { ContentImage } from './ContentImage'
import { TweetPhoto } from './TweetPhoto'
import { smartypantsBlocks } from '../../utils/smartypantsBlocks'

const BlockRenderer = props => {
  const { style = 'normal' } = props.node

  if (/^h\d/.test(style)) {
    const level = style.replace(/[^\d]/g, '')
    return React.createElement(
      style,
      { className: `heading-${level}` },
      props.children
    )
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

const TweetMentionSerializer = props => {
  const author = props.mark.reference
  if (author._type === 'reference') {
    return props.children
  } else {
    return (
      <a href={'https://twitter.com/' + author.username} title={author.name}>
        {props.children}
      </a>
    )
  }
}

const TweetHashtagSerializer = props => {
  return (
    <a
      href={'https://twitter.com/hashtag/' + props.mark.tag}
      title={'#' + props.mark.tag}
    >
      {props.children}
    </a>
  )
}

const TweetLinkSerializer = props => {
  return <a href={props.mark.expandedUrl}>{props.mark.displayUrl}</a>
}

const serializers = {
  types: {
    block: BlockRenderer,
    contentImage: ContentImage,
    code: props => (
      <HighlightCode language={props.node.language} value={props.node.code} />
    ),
    tweetPhoto: TweetPhoto,
  },
  marks: {
    tweetMention: TweetMentionSerializer,
    tweetHashtag: TweetHashtagSerializer,
    tweetLink: TweetLinkSerializer,
    ...BlockContent.defaultSerializers.types,
  },
}

export function PortableText({
  text,
  className,
  renderContainerOnSingleChild = true,
}) {
  return (
    <BlockContent
      blocks={smartypantsBlocks(text)}
      serializers={serializers}
      className={className}
      renderContainerOnSingleChild={renderContainerOnSingleChild}
      projectId="eqnptifd"
      dataset="blog"
    />
  )
}
