import React from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
`

export function TweetPhoto({ node: photo }) {
  return (
    <figure>
      <StyledImage
        src={photo.mediaUrl}
        width={photo.width}
        height={photo.height}
      />
    </figure>
  )
}
