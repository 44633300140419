import { smartypants } from '../utils/smartypants'

export function smartypantsBlocks(blocks) {
  return (
    blocks
      // loop through each block
      .map(block => {
        // if it's not a text block with children, return
        if (block._type !== 'block' || !block.children) {
          return block
        }
        // loop through the children spans, run smartypants
        block.children = block.children.map(child =>
          Object.assign({}, child, { text: smartypants(child.text) })
        )
        return block
      })
  )
}
