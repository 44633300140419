import Typography from 'typography'
import Wordpress2016 from 'typography-theme-wordpress-2016'

Wordpress2016.overrideThemeStyles = ({}, options) => {
  return {
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    h1: {
      fontFamily: options.headerFontFamily.join(','),
    },
    'nav li': {
      fontFamily: options.headerFontFamily.join(','),
      fontWeight: options.headerWeight,
    },
  }
}

Wordpress2016.bodyFontFamily = ['Montserrat', 'sans-serif']
Wordpress2016.headerFontFamily = ['RalewayVariable', 'sans-serif']
Wordpress2016.headerWeight = 600
Wordpress2016.bodyWeight = 500
Wordpress2016.boldWeight = 700

delete Wordpress2016.googleFonts

export const typography = new Typography(Wordpress2016)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
