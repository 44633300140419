import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { smartypants } from '../utils/smartypants'

export function SEO({
  title,
  description,
  image,
  article,
  currentPage,
  numPages,
}) {
  const { pathname } = useLocation()
  const {
    config: {
      title: siteTitle,
      author,
      description: defaultDescription,
      siteUrl,
      keywords,
    },
  } = useStaticQuery(detailsQuery)

  const seo = {
    title: smartypants(title),
    description: smartypants(description || defaultDescription),
    image,
    url: `${siteUrl.replace(/\/$/, '')}${pathname}`,
    twitterUsername: null,
    keywords: keywords.join(`, `),
  }

  author.social.forEach(({ site, username }) => {
    if (site === 'twitter' && !seo.twitterUsername) {
      seo.twitterUsername = username
    }
  })

  if (currentPage && currentPage > 1) {
    seo.title += ` | Page ${currentPage}`
    if (numPages && numPages > currentPage) {
      seo.title += ` of ${numPages}`
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <link rel="canonical" href={seo.url} />
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}

      {seo.url && <meta name="og:url" content={seo.url} />}
      {(article ? true : null) ? (
        <meta property="og:type" content="article" />
      ) : (
        <meta property="og:type" content="website" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {seo.twitterUsername && (
        <meta name="twitter:creator" content={seo.twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      {keywords.length > 0 && <meta name="keywords" content={seo.keywords} />}
    </Helmet>
  )
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  currentPage: PropTypes.number,
  numPages: PropTypes.number,
}

SEO.defaultProps = {
  description: '',
  image: null,
  article: false,
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    config: sanityConfig(_id: { eq: "global-config" }) {
      title
      description
      author {
        social {
          site
          username
        }
      }
      siteUrl
      keywords
    }
  }
`
