import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { PageLink } from '../styled/PageLink'
import { NavList, NavListElement } from '../styled/NavList'

const maxCategories = 5

const StyledNavList = styled(NavList)`
  gap: ${({ theme }) => theme.rhythm(1 / 4)}
    ${({ theme }) => theme.rhythm(1 / 2)};
  margin-top: ${({ theme }) => theme.rhythm(1 / 2)};
  padding-left: ${({ theme }) => theme.rhythm(1)};

  &::before {
    content: 'Categories: ';
  }
`

const StyledNavListElement = styled(NavListElement)`
  ${({ theme }) => theme.scale(0)}
  font-weight: ${({ theme }) => theme.options.bodyWeight};
`

export function NavCategories() {
  const {
    posts: { group: groups },
    categories: { edges: categories },
  } = useStaticQuery(graphql`
    query NavCategoriesQuery {
      posts: allSanityPost {
        group(field: categories___id) {
          count: totalCount
          id: fieldValue
        }
      }
      categories: allSanityCategory(sort: { order: ASC, fields: title }) {
        edges {
          node {
            title
            id
            slug {
              current
            }
          }
        }
      }
    }
  `)

  const countMap = groups.reduce((map, group) => {
    map[group.id] = group.count
    return map
  }, {})

  const sortedCategories = categories
    .map(({ node: category }) => ({
      ...category,
      count: countMap[category.id],
    }))
    .sort((a, b) => b.count - a.count)
    .slice(0, maxCategories)

  return (
    <StyledNavList>
      {sortedCategories.map(category => {
        return (
          <StyledNavListElement key={category.slug.current}>
            <PageLink to={`/categories/${category.slug.current}`}>
              {category.title}
            </PageLink>
          </StyledNavListElement>
        )
      })}
      <StyledNavListElement>
        <PageLink to="/categories">All categories</PageLink>
      </StyledNavListElement>
    </StyledNavList>
  )
}
