import React from 'react'
import SanityImage from 'gatsby-plugin-sanity-image'
import BlockContent from '@sanity/block-content-to-react'
import { smartypantsBlocks } from '../../utils/smartypantsBlocks'

export function ContentImage({ node, options, isInline }) {
  if (!node.asset) {
    return null
  }

  const img = (
    <SanityImage
      asset={node.asset}
      crop={node.crop}
      hotspot={node.hotspot}
      alt={node.alt}
      options={{ __experimentalAspectRatio: true }}
    />
  )
  return isInline ? (
    img
  ) : (
    <figure>
      {img}
      {node.caption.length > 0 && (
        <figcaption>
          <BlockContent
            blocks={smartypantsBlocks(node.caption)}
            projectId={options.projectId}
            dataset={options.dataset}
          />
        </figcaption>
      )}
    </figure>
  )
}
